import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import cover from "../assets/primal/rulebook/cover.png";
import p0 from "../assets/primal/rulebook/p0.png";
import p1 from "../assets/primal/rulebook/p1.png";
import p2 from "../assets/primal/rulebook/p2.png";
import p3 from "../assets/primal/rulebook/p3.png";
import p4 from "../assets/primal/rulebook/p4.png";
import p5 from "../assets/primal/rulebook/p5.png";
import p6 from "../assets/primal/rulebook/p6.png";
import p7 from "../assets/primal/rulebook/p7.png";
import p8 from "../assets/primal/rulebook/p8.png";
import p9 from "../assets/primal/rulebook/p9.png";
import p10 from "../assets/primal/rulebook/p10.png";
import p11 from "../assets/primal/rulebook/p11.png";
import p12 from "../assets/primal/rulebook/p12.png";
import p13 from "../assets/primal/rulebook/p13.png";
import p14 from "../assets/primal/rulebook/p14.png";
import p15 from "../assets/primal/rulebook/p15.png";
import p16 from "../assets/primal/rulebook/p16.png";
import p17 from "../assets/primal/rulebook/p17.png";
import p18 from "../assets/primal/rulebook/p18.png";
import p19 from "../assets/primal/rulebook/p19.png";
import p20 from "../assets/primal/rulebook/p20.png";
import p21 from "../assets/primal/rulebook/p21.png";
import p22 from "../assets/primal/rulebook/p22.png";
import p23 from "../assets/primal/rulebook/p23.png";
import p24 from "../assets/primal/rulebook/p24.png";
import p25 from "../assets/primal/rulebook/p25.png";
import p26 from "../assets/primal/rulebook/p26.png";
import p27 from "../assets/primal/rulebook/p27.png";
import p28 from "../assets/primal/rulebook/p28.png";
import p29 from "../assets/primal/rulebook/p29.png";
import p30 from "../assets/primal/rulebook/p30.png";
import p31 from "../assets/primal/rulebook/p31.png";
import p32 from "../assets/primal/rulebook/p32.png";
import p33 from "../assets/primal/rulebook/p33.png";
import p34 from "../assets/primal/rulebook/p34.png";
import p35 from "../assets/primal/rulebook/p35.png";


export default class Rulebook extends Component {
  render() {
    let pages = [p0 ,p1 ,p2 ,p3 ,p4 ,p5 ,p6 ,p7 ,p8 ,p9 ,p10,p11,p12,p13,p14,p15,p16,p17,p18,p19,p20,p21,p22,p23,p24,p25,p26,p27,p28,p29,p30,p31,p32,p33,p34,p35];
    return (
      <div className="Rulebook">
        <Helmet>
          <title>Primal Trading Card Game Rulebook</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row bkg-red">
        <div className="col-xs-6 col-sm-6 col-md-6 col-xl-6">
              <img src={cover} alt="Primal Trading Card Game Rulebook Cover Page" style={{width: "100%"}} />
            </div>
          {pages.map((page, index) => (
            <div key={`page${index}`} style={{ marginBottom: "1vh"}} className="col-xs-12 col-sm-6 col-md-6">
              <img src={page} alt={`Primal Trading Card Game Rulebook Page ${index + 1}`} style={{width: "100%"}} />
            </div>
          ))}
        </div>
      <Footer />
      </div>
    );
  }
}