import React, { Component } from "react";
import Helmet from "react-helmet";
import Navbar from "../Navbar/Navbar";
import {
  FormControl,
  FormControlLabel,
  TextField,
  CircularProgress,
  Checkbox,
  Button
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  Container,
  Button as FloatingButton
} from "react-floating-action-button";
import { v4 as uuidv4 } from 'uuid';
import Footer from "../Footer/Footer";

import constants from "../Constants/constants";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      characteristic: "",
      cardEffect: "",
      rarityQuery: [],
      cardSetQuery: [],
      archetypeQuery: [],
      elementsQuery: [],
      typeQuery: [],
      turnCountQuery: [],
      combatQuery: [],
    }
  }
  componentDidMount() {
    
  }
  submitSearch() {
    let name = this.state.search;
    name = name.trim();
    let element = this.state.elementsQuery; 
    let type = this.state.typeQuery;
    // let combatAttr = this.state.combatQuery;
    let rarity = this.state.rarityQuery;
    let turnCount = this.state.turnCountQuery;
    // let setList = this.state.cardSetQuery;
    let cardChar = this.state.characteristic; 
    cardChar = cardChar.trim();
    let printedEffect = this.state.cardEffect;
    printedEffect = printedEffect.trim();
    // let endpoint = 'https://api.narutoccg.com/api/v1/posts?'
    let endpoint = `${constants.DOMAIN}api/v1/guest/post?`
    if (name !== 'Search by Card Name (Micromon Krakaan)' || '') {
      // not just filters but actually searched a card
      endpoint += `search=${name}&`
    }  
    if (cardChar !== 'Search by Characteristics (Transformation Female)' || ''){
      endpoint += `characteristic=${cardChar}&`
    }
    if (printedEffect !== 'Search by Card Effect' || '') {
      endpoint += `cardEffect=${printedEffect}&`;
    }
    if (rarity.length > 0) {
      const _rarity = rarity.join("|");
      endpoint += `rarity=${_rarity}&`;
    }
    if (element.length > 0) {
      const _element = element.join("|");
      endpoint += `element=${_element}&`;
    }
    if (type.length > 0) {
      const _type = type.join("|");
      endpoint += `type=${_type}&`;
    }
    if (turnCount.length > 0) {
      const _turnCount = turnCount.join("|");
      endpoint += `turnCount=${_turnCount}&`;
    }
    // if (combatAttr.length > 0) {
    //   const _combatAttr = combatAttr.join("|");
    //   endpoint += `skill=${_combatAttr}&`;
    // }
    // if (setList.length > 0) {
    //   const _setList= setList.join("|");
    //   endpoint += `cardSet=${_setList}&`;
    // }
    fetch(endpoint, {
      mode: 'cors',
      method: 'GET'
    }).then(async res => {
      res = await res.json()
      let posts = res.posts // array
      console.log("CARDS SEARCHED", posts)
      let uniqueReq = uuidv4();
      this.props.history.push(`/search/results/${uniqueReq}`, { cards: posts });
    })
      .catch(err => {
        console.log(err);
      })
  }
  /**
   * Renders TextInput with FromControl
   *
   * @param {string} id
   * @param {string} label
   * @param {string} value
   * @param {any} width Can be int or string, controls the width of the form control
   * @returns {React$Element<FormControl>}
   * @memberof NewShipmentComponent
   */
   renderTextInput(
    id,
    label,
    value,
    width,
    inputProps,
    disabled = false,
    error = false
  ) {
    return (
      <FormControl style={{ width: width }}>
        <TextField
          error={error}
          variant="outlined"
          id={id}
          label={label}
          value={value || ""}
          disabled={disabled}
          InputProps={inputProps}
          autoComplete="nope"
          onChange={(inputValue) => {
            this.setState({ [id]: inputValue.target.value });
          }}
        />
      </FormControl>
    );
  }
  determineChecked(state, value) {
    return state.includes(value);
  }
  handleCheckedEvent(stateIdentifier, value) {
    if (this.state[stateIdentifier].includes(value)) {
      // Uncheck
      let index = this.state[stateIdentifier].indexOf(value);
      let newState = [...this.state[stateIdentifier]];
      newState.splice(index, 1);
      this.setState({ [stateIdentifier]: newState });
    } else {
      // check
      let newState = [...this.state[stateIdentifier]];
      newState.push(value);
      this.setState({ [stateIdentifier]: newState });
    }
  }
  renderCheckbox(
    id, // state
    label,
    value // value to add/remove to state
  ) {
    let checked = this.determineChecked(id, value)
    return (                  
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            color="primary"
            checked={checked}
            onChange={event => {
              // this.handleCompanyGroupSelection(
              //   companyGroup,
              //   checked
              // );
            }}
          />
        }
        label={label}
      />
    </FormControl>);
  }
  render() {
    const { search,
      characteristic,
      cardEffect,
      rarityQuery,
      cardSetQuery,
      archetypeQuery,
      elementsQuery,
      typeQuery,
      turnCountQuery,
      combatQuery
    } = this.state;
    const elements = ["Fire", "Water", "Terra", "Air", "Plasma", "Necro"];
    const rarity = ["Secret Rare", "Legendary Rare", "Super Rare", "Rare", "Uncommon", "Common", "Promo"];
    const cardType = ["Character", "Ability", "Strategy"];
    const turnCount = ["0", "1", "2", "3", "4", "5", "6", "7", "8","9"]
    return (
      <div className="Search">
        <Helmet>
          <title>Primal Trading Card Game Search</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row bkg-red full-h">
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          <h1 className="mb-2vh">Search</h1>
          <div className="container mb-2vh">
          {this.renderTextInput("search", "Search by Card Name (Micromon Krakaan)", search, "100%")}
          </div>
          <div className="container mb-2vh">
          {this.renderTextInput("characteristic", "Search by Characteristics (Transformation Female)", characteristic, "100%")}
          </div>
          <div className="container mb-2vh">
          {this.renderTextInput("cardEffect", "Search by Card Effect", cardEffect, "100%")}
          </div>
          <div className="row">
            <div className="col-md-6">
              <h3 style={{ fontWeight: "bold"}} >Element(s)</h3>
              {elements.map((el, ind) => {
                let checked = this.determineChecked(elementsQuery, el)
                return (<FormControl key={el + ind}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"elementsQuery"}
                        style={{ color: "#9D0208" }}
                        checked={checked}
                        onChange={event => {
                          this.handleCheckedEvent("elementsQuery", el)
                        }}
                      />
                    }
                    label={el}
                  />
                </FormControl>)
              })}
            </div>
            <div className="col-md-6">
              <h3 style={{ fontWeight: "bold"}} >Rarity</h3>
              {rarity.map((el, ind) => {
                let checked = this.determineChecked(rarityQuery, el)
                return (<FormControl key={el + ind}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"rarityQuery"}
                        style={{ color: "#9D0208" }}
                        checked={checked}
                        onChange={event => {
                          this.handleCheckedEvent("rarityQuery", el)
                        }}
                      />
                    }
                    label={el}
                  />
                </FormControl>)
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h3 style={{ fontWeight: "bold"}} >Card Type</h3>
              {cardType.map((el, ind) => {
                let checked = this.determineChecked(typeQuery, el)
                return (<FormControl key={el + ind}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"typeQuery"}
                        style={{ color: "#9D0208" }}
                        checked={checked}
                        onChange={event => {
                          this.handleCheckedEvent("typeQuery", el)
                        }}
                      />
                    }
                    label={el}
                  />
                </FormControl>)
              })}
            </div>
            <div className="col-md-6">
              <h3 style={{ fontWeight: "bold"}} >Turn Count</h3>
              {turnCount.map((el, ind) => {
                let checked = this.determineChecked(turnCountQuery, el)
                return (<FormControl key={el + ind}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"turnCountQuery"}
                        style={{ color: "#9D0208" }}
                        checked={checked}
                        onChange={event => {
                          this.handleCheckedEvent("turnCountQuery", el)
                        }}
                      />
                    }
                    label={el}
                  />
                </FormControl>)
              })}
            </div>
          </div>
        </div>
        <Container>
        <FloatingButton
                    styles={{
                      cursor: "pointer",
                      backgroundColor: "blue"
                    }}
                    onClick={() => {
                      this.submitSearch();
                    }}
                  >
                      <SearchIcon
                        htmlColor={"white"}
                      />
                  </FloatingButton>
        </Container>

      </div>
      <Footer />
      </div>
    );
  }
}