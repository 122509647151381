import React, { Component } from 'react';
import Helmet from 'react-helmet';
import './Front.css';
import AuthService from './AuthService';
import Navbar from "./Navbar/Navbar";
import Banner from "./Banner/Banner";
import ShopBanner from "./Banner/ShopBanner";
import SubscribeBanner from './Banner/SubscribeBanner';
import bannerDesk6 from '../components/assets/primal/front/banners/bannerDesk6.png';
import bannerDesk7 from '../components/assets/primal/front/banners/bannerDesk7.png';
import bannerDesk8 from '../components/assets/primal/front/banners/bannerDesk8.png';
import subscribeBkg from '../components/assets/primal/front/banners/subscribeBG.png';
import playingBkg from '../components/assets/primal/front/banners/playingBanner.JPG';
import productImage from '../components/assets/primal/front/banners/cardsBanner.png';
// import bannerDesk2 from '../components/assets/primal/front/banners/bannerDesk2.png';
// import bannerDesk3 from '../components/assets/primal/front/banners/bannerDesk3.png';
// import bannerDesk4 from '../components/assets/primal/front/banners/bannerDesk4.png';
// import bannerDesk5 from '../components/assets/primal/front/banners/bannerDesk5.png';
import main from "../components/assets/primal/preorder/main.png"
import mobileBanner from '../components/assets/primal/front/banners/bannerMobile4.png';
import bannerMobile2 from '../components/assets/primal/front/banners/bannerMobile2.png';
import bannerMobile3 from '../components/assets/primal/front/banners/bannerMobile3.png';
import ProductBtn from '../components/assets/primal/front/2023ProductBtn.png';
import ProductBtn2 from '../components/assets/primal/front/2024ProductST0102Btn.png';
import ProductBtn4 from '../components/assets/primal/front/2024ProductST36PDBtn.png';
import ProductBtn3 from '../components/assets/primal/front/2024ProductAPBtn.png';
import ProductBtn5 from '../components/assets/primal/front/2024ProductBBBtn.png';
import PPNImage from '../components/assets/primal/front/primal-hero-1.webp';
import OP2023Btn from '../components/assets/primal/front/OP2023Btn.png'; 
import meSM from '../components/assets/primal/front/meSM.png';
import bagSM from '../components/assets/primal/front/bagSM.png';
import mardo from '../components/assets/primal/front/mardo.png';
import Demo from '../components/assets/primal/front/Demo.png';
import AmbassadorProgram from '../components/assets/primal/front/AmbassadorProgram.png';
import OPSchedule from '../components/assets/primal/front/OPSchedule.png';
import PrereleaseSzn from '../components/assets/primal/front/PrereleaseSzn.png';
import PrimalNewPlayerBtn from '../components/assets/primal/front/PrimalNewPlayer.png';
import PrimalNewRetailerBtn from '../components/assets/primal/front/PrimalNewRetailer.png';
import card1 from '../components/assets/primal/front/card1.png';
import card2 from '../components/assets/primal/front/card2.png';
import card3 from '../components/assets/primal/front/card3.png';
import card4 from '../components/assets/primal/front/card4.png';
import card5 from '../components/assets/primal/front/card5.png';
import card6 from '../components/assets/primal/front/card6.png';
import card7 from '../components/assets/primal/front/card7.png';
import card8 from '../components/assets/primal/front/card8.png';
import card9 from '../components/assets/primal/front/card9.png';
import C0001 from '../components/assets/primal/front/cards/C0001.png';
import C0005 from '../components/assets/primal/front/cards/C0005.png';
import C0006 from '../components/assets/primal/front/cards/C0006.png';
import C0011 from '../components/assets/primal/front/cards/C0011.png';
import C0018 from '../components/assets/primal/front/cards/C0018.png';
import C0020 from '../components/assets/primal/front/cards/C0020.png';
import C0038 from '../components/assets/primal/front/cards/C0038.png';
import C0044 from '../components/assets/primal/front/cards/C0044.png';
import C0047 from '../components/assets/primal/front/cards/C0047.png';
import C0052 from '../components/assets/primal/front/cards/C0052.png';
import C0055 from '../components/assets/primal/front/cards/C0055.png';
import C0061 from '../components/assets/primal/front/cards/C0061.png';
import C0068 from '../components/assets/primal/front/cards/C0068.png';
import appImage from "../components/assets/primal/branding/appImage.png"
import bannerMobileApp from "../components/assets/primal/branding/bannerMobileApp.png"
import guidePDF from "../components/assets/primal/front/2024PrimalTCGGuide.pdf";
import Footer from "./Footer/Footer";
import contactbkg from '../components/assets/naruto/front/contactbkg.jpg';
// import withAuth from './components/WithAuth';


// mixpanel.init('6355b9138ef99a42321be6306756111e');
class Front extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      result: null,
      query: '',
      showItems: 30,
      columnStyle: 'col-md-6',
      isAuth: false,
      cardanimate: 'anicards hide',
      uri: '#',
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      numPages: 1
    };
    this.Auth = new AuthService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        cardanimate: 'anicards',
      });
    }, 500);
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|macintosh/.test(userAgent);
    };
    if (isIos()) {
      this.setState({
        uri: '#',
      });
    }
  }

  sendMessage() {
    let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    let mssgArr = this.state.message.split(" ");
    let verifiedEmail = re.test(this.state.email);
    if (
      this.state.message !== '' &&
      mssgArr.length > 2 &&
      this.state.firstName !== '' &&
      verifiedEmail
    ) {
      let details = {
        'message': this.state.message,
        'name': this.state.firstName + ' ' + this.state.lastName,
        'fromEmail': this.state.email,
      };
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody,
      }).then((response) => response.json())
        .then((responseData) => {
          this.setState({
            message: '',
            email: '',
            firstName: '',
            lastName: '',
          });
          alert("We will email you back promptly");
        })
        .catch(err => {
          console.log(err);
        });
    }
    else {
      alert('Please fill out the fields with appropriate values');
    }
  }
  joinDiscord() {
    window.open("https://discord.gg/pxTRcC32nZ");
  }
  linkTree() {
    window.open("https://linktr.ee/primaltcg");
  }
  joinCommunity() {
    let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    let mssgArr = this.state.message.split(" ");
    let verifiedEmail = re.test(this.state.email);
    if (
      verifiedEmail
    ) {
      let details = {
        'message': "WANTS TO JOIN THE PRIMAL TCG MAILING LIST",
        'name': "NEW USER",
        'fromEmail': this.state.email,
      };
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody,
      }).then((response) => response.json())
        .then((responseData) => {
          this.setState({
            message: '',
            email: '',
            firstName: '',
            lastName: '',
          });
          alert("Thanks for joining! You'll be receiving updates from us soon!");
        })
        .catch(err => {
          console.log(err);
        });
    }
    else {
      alert('Please fill out the fields with appropriate values');
    }
  }
  becomeBetaTester() {
    let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    let mssgArr = this.state.message.split(" ");
    let verifiedEmail = re.test(this.state.email);
    if (
      verifiedEmail
    ) {
      let details = {
        'message': "WANTS TO BECOME A BETA TESTER AND JOIN THE PRIMAL TCG MAILING LIST",
        'name': "NEW BETA TESTER APPLICATION",
        'fromEmail': this.state.email,
      };
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch('https://api.narutoccg.com/api/v1.1/market/sendmail', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody,
      }).then((response) => response.json())
        .then((responseData) => {
          this.setState({
            message: '',
            email: '',
            firstName: '',
            lastName: '',
          });
          alert("We will contact you back promptly with further instructions. Thank you for applying.");
        })
        .catch(err => {
          console.log(err);
        });
    }
    else {
      alert('Please fill out the fields with appropriate values');
    }
  }
  onDocumentLoadSuccess({ numPages }) {
    /**
     * const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
     */
    this.setState({
      numPages: numPages
    })
  }
  render() {
    let desktopBanners = [bannerDesk6, bannerDesk7, bannerDesk8];
    let desktopBanner = desktopBanners[Math.floor(Math.random() * desktopBanners.length)];

    return (
      <div className="Front">
        <Helmet>
          <title>Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Set</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024. Follow us to get early access to cards and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials Funded on Kickstarter September 2022" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024. Follow us to get early access to cards and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <Banner
          banner={desktopBanner}
          mobileBanner={mobileBanner}
        />
        <SubscribeBanner
          banner={playingBkg}
          mobileBanner={subscribeBkg}
          productImage={productImage}
        />
                        <div id="productLine" className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div className="section section-content section-black">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      <h2 style={{ color: "white" }} className="title">Product</h2>
                      <div className='row'>
                      <div className='col-sm-12'>
                      <img onClick={() => {
                          window.open("https://primaltcg.com/bethesda")
                        }} style={{cursor: "pointer"}} id="productImage" src={ProductBtn5} />
                      </div>
                      <div className='col-sm-12'>
                      <img onClick={() => {
                          window.open("https://shop.primaltcg.com/collections/2024-primal-tcg-1st-ed")
                        }} style={{cursor: "pointer"}} id="productImage" src={ProductBtn4} />
                      </div>
                      <div className='col-sm-12'>
                      <img onClick={() => {
                          window.open("https://shop.primaltcg.com/collections/2024-primal-tcg-1st-ed")
                        }} style={{cursor: "pointer"}} id="productImage" src={ProductBtn3} />
                      </div>
                      <div className='col-sm-12'>
                      <img onClick={() => {
                          window.open("https://shop.primaltcg.com/collections/2024-primal-tcg-1st-ed")
                        }} style={{cursor: "pointer"}} id="productImage" src={ProductBtn2} />
                      </div>
                      <div className='col-sm-12'>
                      <img onClick={() => {
                          window.open("https://shop.primaltcg.com")
                        }} style={{cursor: "pointer"}} id="productImage" src={ProductBtn} />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div className="section section-content section-black">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      
                      <h2 style={{ color: "white" }} className="title">Primal TCG Community Map</h2>
                      <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1wC2fP34-UN7ebAkuXzRg2KchDZtv8Pg&ehbc=2E312F" frameborder="0" style={{overflow:"hidden",height:"100vh",width:"100%"}} height="100%" width="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                <div className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div className="section section-content section-black">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      
                      <h2 style={{ color: "white" }} className="title">Get Started Today</h2>
                      <div className='row'>
                      <div className='col-sm-6'>
                        <img onClick={() => {
                          // this.props.history.push("/new-player")
                          window.open("https://discord.gg/pxTRcC32nZ");
                        }} style={{cursor: "pointer"}} id="productImage" src={PrimalNewPlayerBtn} />
                      </div>
                      <div className='col-sm-6'>
                        <img onClick={() => {
                          this.props.history.push("/salesheet")
                          // window.open("https://forms.gle/dz3f4pLYna9CGswb6");
                        }}  style={{cursor: "pointer"}} id="productImage" src={PrimalNewRetailerBtn} />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 ml-auto mr-auto">
                        <h2 className="title text-center">Latest Updates</h2>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-plain card-blog text-center">
                            <div className="card-image">
                                <a href="https://primal.bestcoastpairings.com/event/HV7MRKFEU7">
                                    <img className="img img-raised" src={"https://d17plg9ep1ptvd.cloudfront.net/2024-04-11/XQJV-KC26-WRKD.png"} />
                                </a>
                            </div>
                            <div className="card-body">
                                <h6 className="card-category text-danger">Premier Tournament</h6>
                                <h3 className="card-title">
                                    <a href="https://primal.bestcoastpairings.com/event/HV7MRKFEU7">May 26th First $5K Tournament of 2024</a>
                                </h3>
                                <p className="card-description">
                                    We welcome you to The Haven Games in Orlando, FL during Memorial Day Weekend for a chance to participate in Primal TCG's first premier tournament of the year! Pick up a starter deck, and a booster box and craft up your own deck to participate in this competitive event. Pre-register to win a exclusive stamped promo on tourney day.
                                </p>
                                <br/>
                                <a href="https://primal.bestcoastpairings.com/event/HV7MRKFEU7" className="btn btn-danger btn-round"> Register for Orlando $5K Tournament</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-plain card-blog text-center">
                            <div className="card-image">
                                <a href="https://play.primaltcg.com">
                                    <img className="img img-raised" src={"https://pbs.twimg.com/media/GLjxNUGXUAAeMJU?format=jpg&name=medium"} />
                                </a>
                            </div>
                            <div className="card-body">
                                <h6 className="card-category text-info">Partnership</h6>
                                <h3 className="card-title">
                                    <a href="https://play.primaltcg.com">Best Coast Pairings Presents the Primal Play Network</a>
                                </h3>
                                <p className="card-description">
                                We are thrilled to announce our partnership with Best Coast Pairings to enhance our Primal Play Network. Tournament hosts and players alike can now enjoy a seamless pairing system for an even more competitive edge.
                                </p>
                                <br/>
                                <a href="https://play.primaltcg.com" className="btn btn-primary btn-round"> Open Play Network</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-plain card-blog text-center">
                            <div className="card-image">
                                <a href="https://forms.gle/dz3f4pLYna9CGswb6">
                                    <img className="img img-raised" src={Demo} />
                                </a>
                            </div>
                            <div className="card-body">
                                <h6 className="card-category text-danger">New Retailers</h6>
                                <h3 className="card-title">
                                    <a href="https://forms.gle/dz3f4pLYna9CGswb6">Pick up your free demo decks</a>
                                </h3>
                                <p className="card-description">
                                    Build a local Primal TCG scene at your shop by providing free demo decks to your player base. If you have players that like TCGs with anime art and a high skill ceiling they'll definitely make Primal their second main TCG.
                                </p>
                                <br/>
                                <a href="https://forms.gle/dz3f4pLYna9CGswb6" className="btn btn-danger btn-round"> Apply for Welcome Kits</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-plain card-blog text-center">
                            <div className="card-image">
                                <a href="/optour">
                                    <img className="img img-raised" src={OPSchedule} />
                                </a>
                            </div>
                            <div className="card-body">
                                <h6 className="card-category text-info">Organized Play & Release Dates</h6>
                                <h3 className="card-title">
                                    <a href="/optour">2024 and the road ahead</a>
                                </h3>
                                <p className="card-description">
                                    Catch us in the conventions and participate in our premier tournaments. Join our online championship series too to get a chance to compete with our entire Primal TCG community through Discord webcam matches. Don't miss out on the next set drops!
                                </p>
                                <br/>
                                <a href="/optour" className="btn btn-primary btn-round"> Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-plain card-blog text-center">
                            <div className="card-image">
                                <a href="/ambassador">
                                    <img className="img img-raised" src={AmbassadorProgram} />
                                </a>
                            </div>
                            <div className="card-body">
                                <h6 className="card-category text-danger">Community</h6>
                                <h3 className="card-title">
                                    <a href="/ambassador">Become a Primal Ambassador today</a>
                                </h3>
                                <p className="card-description">
                                    Help Primal TCG grow in your local community! Host learn to play events, show off your community on social media and get exclusive Ambassador Kits!
                                </p>
                                <br/>
                                <a href="/ambassador" className="btn btn-danger btn-round"> Read More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-plain card-blog text-center">
                            <div className="card-image">
                                <a href="/prerelease">
                                    <img className="img img-raised" src={PrereleaseSzn} />
                                </a>
                            </div>
                            <div className="card-body">
                                <h6 className="card-category text-info">Retailers</h6>
                                <h3 className="card-title">
                                    <a href="/prerelease">Running a Pre-Release Event</a>
                                </h3>
                                <p className="card-description">
                                    If we were to run a Primal TCG pre-release event this is how we would run one. Check out our team's suggestions on how you can run your pre-release event.
                                </p>
                                <br/>
                                <a href="/prerelease" className="btn btn-info btn-round"> Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>

        <ShopBanner
          banner={appImage}
          mobileBanner={bannerMobileApp}
        />
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div style={{ backgroundColor: "#9D0208" }} className="section section-content">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      <h2 style={{ color: "white" }} className="title">Follow Us For Updates!</h2>
                      <span>
                        <p style={{ color: "white" }}>
                          Want some promos and prizes? Keep up to date with us!
                        </p>
                        <button
                          className="altSubmission"
                          onClick={() => this.linkTree()}
                        >Follow Us</button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM SECTION */}
        <div className="team-1">
            <div className="container">
    			<div className="row">
    				<div className="col-md-8 ml-auto mr-auto text-center">
    					<h2 className="title">Our Stunning Team</h2>
    					<h5 className="description">We have three heads in charge of making Primal TCG happen. Claudio is focused on the business and logistics, Mardo is focused on creating scalable card sets that have a balanced growth, Bagira is focused on having the community play Primal TCG and expand our player base.</h5>
    				</div>
    			</div>
    			<div className="row">
    				<div className="col-md-4">
                        <div className="card card-profile card-plain">
                            <div className="card-avatar">
                                <a href="#avatar"><img src={meSM} alt="Claudio Image" /></a>
                            </div>
                            <div className="card-body">
                                <a href="#paper-kit">
                                    <div className="author">
                                        <h4 className="card-title">Claudio Atilano</h4>
                                        <h6 className="card-category text-muted">Founder & Manager</h6>
                                    </div>
                                </a>
                                <p className="card-description text-center">
                                Responsible for the direction of Primal TCG, partnerships and logistics. I love this game and want to show the world how it's the greatest card game ever made. I have a tech background and I wish I had more time to play Primal.
                                </p>
                            </div>
                            <div className="card-footer text-center">
                                <a href="https://twitter.com/claudioatilano" className="btn btn-link btn-just-icon btn-twitter"><i className="fa fa-twitter"></i></a>
                                <a href="https://linkedin.com/in/claudioatilano" className="btn btn-link btn-just-icon btn-linkedin"><i className="fa fa-linkedin"></i></a>
                            </div>
                        </div>
    				</div>

    				<div className="col-md-4">
                        <div className="card card-profile card-plain">
                            <div className="card-avatar">
                                <a href="#avatar"><img src={mardo} alt="Mardo Image" /></a>
                            </div>
                            <div className="card-body">
                                <a href="#paper-kit">
                                    <div className="author">
                                        <h4 className="card-title">Angel "Mardo" Garcia</h4>
                                        <h6 className="card-category text-muted">Lead Designer</h6>
                                    </div>
                                </a>
                                <p className="card-description text-center">
                                I thrive on exploring the depths of creative game design, constantly pushing the boundaries and exploring new possibilities with each mechanic, all while meticulously maintaining a delicate balance. Witnessing the ingenuity of players as they craft decks using the tools I've provided is one of my greatest joys.
                                </p>
                            </div>
                            {/* <div className="card-footer text-center">
                                <a href="#pablo" className="btn btn-link btn-just-icon btn-twitter"><i className="fa fa-twitter"></i></a>
                                <a href="#pablo" className="btn btn-link btn-just-icon btn-dribbble"><i className="fa fa-dribbble"></i></a>
                                <a href="#pablo" className="btn btn-link btn-just-icon btn-linkedin"><i className="fa fa-linkedin"></i></a>
                            </div> */}
                        </div>
    				</div>

    				<div className="col-md-4">
                        <div className="card card-profile card-plain">
                            <div className="card-avatar">
                                <a href="#avatar"><img src={bagSM} alt="Bagira Image" /></a>
                            </div>
                            <div className="card-body">
                                <a href="#paper-kit">
                                    <div className="author">
                                        <h4 className="card-title">Michael "Bagira" Beaumont</h4>
                                        <h6 className="card-category text-muted">Director of Organized Play</h6>
                                    </div>
                                </a>
                                <p className="card-description text-center">
                                A trading card game enthusiast who loves bringing people together. Whether it's battling it out in tournaments or hosting laid-back game nights, I'm all about building friendships and enjoying our shared passion for the game.
                                </p>
                            </div>
                            {/* <div className="card-footer text-center">
                                <a href="#pablo" className="btn btn-link btn-just-icon btn-twitter"><i className="fa fa-twitter"></i></a>
                                <a href="#pablo" className="btn btn-link btn-just-icon btn-dribbble"><i className="fa fa-dribbble"></i></a>
                                <a href="#pablo" className="btn btn-link btn-just-icon btn-linkedin"><i className="fa fa-linkedin"></i></a>
                            </div> */}
                        </div>
    				</div>
    			</div>
		    </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div className="section section-content section-black">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      <h2 style={{ color: "white" }} className="title">Events</h2>
                      <div className='row'>
                      <div className='col-sm-12'>
                      <img onClick={() => {
                          window.open("https://play.primaltcg.com")
                        }} style={{cursor: "pointer"}} id="productImage" src={OP2023Btn} />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div className="section section-content section-black">
                <div className="container">
                  <div className="row">
                    <div style={{ textAlign: "center" }} className="col-sm-12">
                      <h2 style={{ color: "white" }} className="title">Join our Discord Community</h2>
                      <span>
                        <p style={{ color: "white" }}>
                          Get exclusive access to our cards and give us feedback!
                        </p>
                        <button
                          className="emailSubmission"
                          onClick={() => this.joinDiscord()}
                        >Join Discord Server</button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row animatedCardsSection">
          <div className="col-md-12 col-xs-12">
            <div className="section-cards section-white">
              <div className="codrops-header">
                <div id="Awakened-Power" className="row">
                  <div className="col-md-6 col-lg-4 col-xs-12">
                    <div className="section-description">
                      <img alt="Primal Trading Card Game Card Types" className="mobileViewCards" src={card8} />
                      <h3 className="title">Awakened Primordials</h3>
                      <h4 className="category">Choose the archetype that fits you!</h4>
                      <br />
                      <img alt="Primal Trading Card Game Waifu Cayne Pirate Hari" className="mobileViewCards" src={C0001} />
                      <h6 className="subcategory">Cayne Pirates</h6>
                      <h5 className="description">Sworn an oath to their Captain, Alexander Cayne, AKA Captain Cayne, these sneaky pirates are out to steal resources from their opponent to win any battle. 
                      Pirates like to swarm the field to overwhelm their opponent. This crew filled with female pirates sure can pack a punch!</h5>
                      <br />
                      <img alt="Primal Trading Card Game Fantasy Creature Micromon Chrawnus" className="mobileViewCards" src={C0038} />
                      <h6 className="subcategory">Micromons</h6>
                      <h5 className="description">
                        Micromons live in the Microsphere and the Necrosphere of the world. Micromons are small monsters that can transform
                        to more powerful versions of themselves and they change identities when they transform. One of the leaders of the Microsphere
                        forest is Micromon Vavista. Micromons frighten their opponents enough to paralyze them for a moment when they are battling. 
                      </h5>
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="row">
                        <div className="mobileElementContainer">
                          <img alt="Primal Trading Card Game Necro Air Elements" className="mobileViewCards" src={card5} />
                        </div>
                        <div className="mobileElementContainer">
                          <img alt="Primal Trading Card Game Terra Plasma Elements" className="mobileViewCards" src={card1} />
                          <img alt="Primal Trading Card Game Sea Element" className="mobileViewCards" src={card2} />
                          <img alt="Primal Trading Card Game Fire Element" className="mobileViewCards" src={card6} />
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <img alt="Primal Trading Card Game Waifu Draginked Kamiko" className="mobileViewCards" src={C0055} />
                      <h6 className="subcategory">Draginked</h6>
                      <h5 className="description">
                        The Draginked hosts are humans that have made a deal with Dragons to allow them to live within their body. Dragons have made this deal
                        so they are not hunted down by other humans in the modern era. Whenever the Draginked hosts feel threatened the Dragon within them can be summoned
                        to protect the host. The Draginked hosts attack their opponents by milling their deck out and even controlling their opponent's hand.
                      </h5>
                      <br />
                      <img alt="Primal Trading Card Game Dragon Symbiote Astrophanes" className="mobileViewCards" src={C0068} />
                      <h6 className="subcategory">Symbiote</h6>
                      <h5 className="description">
                        These dragons used to roam the land of Arizal and conquer until the power of the Six Elemental Crystals was discovered. These Six Elemental Crystals can be used to manipulate dragons and even manipulate space
                        time itself. These dragons found faithful allies that they could trust their lives with so that they could restore their dominion over Arizal.
                      </h5>
                      <br />
                      <img alt="Primal Trading Card Game Pleaguis Zovidi Plague Doctor Pirate" className="mobileViewCards" src={C0020} />
                      <h6 className="subcategory">Pleaguis</h6>
                      <h5 className="description">
                        The Plague Doctors have emerged due to the madness of the latest pandemic. Pleaguis are humanoids and humans dedicated to medicine and necromancy. 
                        Pleaguis use the discard pile and cards that are removed from the game to fight their opponent. The biggest threats to lookout for are Pleaguis Buckeye
                        and his Explosive Retort. 
                      </h5>
                      <br />
                      {/* <h3 className="title">Connect with community members</h3>
                      <h6 className="category">C0044 with others to grow your collection</h6>
                      <h5 className="description">We have gone above and beyond to give the community access to all the cards from the Naruto CCG and even included the recently fanmade Set 29, A Shinobi's Dream.</h5> */}
                    </div>
                  </div>
                </div>
              </div>
              <section className="section-intro ">
                <div className={`isolayer isolayer--deco1 isolayer--shadow ${this.state.cardanimate}`}>
                  <ul className="grid">
                    <li className="grid__item first-card">
                      <a className="grid__link" href={this.state.uri}>

                        <img alt="Primal Trading Card Game Terra Plasma Elements" className="grid__img layer" src={card1} />
                      </a>
                    </li>
                    <li className="grid__item second-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Sea Element" className="grid__img layer" src={card2} />
                      </a>
                    </li>
                    <li className="grid__item third-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Card Back" className="grid__img layer" src={card3} />
                      </a>
                    </li>
                    <li className="grid__item fourth-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Icon" className="grid__img layer" src={card4} />
                      </a>
                    </li>
                    <li className="grid__item fifth-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Necro Air Elements" className="grid__img layer" src={card5} />
                      </a>
                    </li>
                    <li className="grid__item sixth-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Fire Element" className="grid__img layer" src={card6} />
                      </a>
                    </li>
                    <li className="grid__item seventh-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Micromon Krakaan Purple Tentacle Squid Sea Monster" className="grid__img layer" src={card7} />
                      </a>
                    </li>
                    <li className="grid__item eight-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Card Types" className="grid__img layer" src={card8} />
                      </a>
                    </li>
                    <li className="grid__item ninth-card">
                      <a className="grid__link" href={this.state.uri}>
                        <img alt="Primal Trading Card Game Symbiote Balthasar Dragon Green Fire" className="grid__img layer" src={card9} />
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div id="fadeInAnim">
              <div className="section section-content section-gray">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <img id="productImage" alt="Primal Trading Card Game Cards" src={"https://pbs.twimg.com/media/FtMuwPRWYAEk76q?format=jpg&name=4096x4096"} style={{ cursor: "pointer" }} onClick={() => { window.open("https://www.kickstarter.com/projects/raikirito/primal-trading-card-game-tcg-awakened-primordials")}} />
                    </div>

                    <div className="col-md-4">
                      <div className="section-description">
                        <h3 className="title">Kickstarter Release</h3>
                        <h6 className="category">In 2022, we fundraised up to $75,000 on Kickstarter</h6>
                        <h5 className="description">We were able to create all of our 2023 products, grow our team, and travel across the U.S. in major TCG conventions to get feedback on our 1st Edition release. </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cd-section section-white" id="contact-us">
    	<div className="contactus-1 section-image" style={{ backgroundImage: `url(${contactbkg})`}}>
    		<div className="container">
    			<div className="row">
                <div className="col-md-10 ml-auto mr-auto">
                  <div className="card card-contact no-transition">
                    <h3 className="card-title text-center">Contact Us</h3>
                    <div className="row">
                      <div className="col-md-5 ml-auto">
                        <div className="card-body">
                          <div className="info info-horizontal">
                            <div className="icon icon-info">
                              <i className="nc-icon nc-pin-3" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Find us at the office</h4>
                              <p> Dattebayo Labs LLC<br />
                             4296 SW 9th Ter,<br />
                                  Miami, FL 33134
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="nc-icon nc-badge" aria-hidden="true"></i>
                            </div>
                            <div className="description">
                              <h4 className="info-title">Give us a ring</h4>
                              <p> Dattebayo Labs LLC<br/>
                                  +1 786 663 0320<br/>
                                  Mon - Fri, 6:00 PM-10:00 PM EST
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mr-auto">
                        <div id="contact-form">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">First name</label>
                                  <input onChange={(firstName) => this.setState({ firstName: firstName.target.value })} type="text" name="name" className="form-control" placeholder="First Name" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group label-floating">
                                  <label className="control-label">Last name</label>
                                  <input onChange={(lastName) => this.setState({ lastName: lastName.target.value })} type="text" name="name" className="form-control" placeholder="Last Name" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Email address</label>
                              <input onChange={(email) => this.setState({ email: email.target.value })} type="email" name="email" className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group label-floating">
                              <label className="control-label">Your message</label>
                              <textarea onChange={(message) => this.setState({ message: message.target.value })} name="message" className="form-control" id="message" rows="6" placeholder="Message"></textarea>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="checkbox">
                                  <input id="checkbox1" type="checkbox" />
                                  <label htmlFor="checkbox1">
                                                            I'm not a robot !
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <button type="submit" onClick={() => this.sendMessage()}className="btn btn-primary pull-right">Send Message</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    			</div>
    		</div>
    	</div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Front;
